.App{
  background-color: #e7e8ef;
  height: 100vh;
  padding: 1rem 1rem 0px 1rem;
  
}

.App:hover{
 
}

.Darkapp{
  background-color: #111827; /* This is the hex value for bg-gray-900 */
  height: 100vh;
  padding: 1rem 1rem 0px 1rem;
}